import { Button, message, notification, Typography, Upload } from "antd";
import getBlobDuration from "get-blob-duration";
import { min } from "moment";
import React from "react";
import RecordRTC, { getSeekableBlob } from "recordrtc";
import videojs from "video.js";
import "video.js/dist/video-js.css";
/*
// Required imports when recording audio-only using the videojs-wavesurfer plugin
import WaveSurfer from 'wavesurfer.js';
import MicrophonePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.microphone.js';
WaveSurfer.microphone = MicrophonePlugin;

// Register videojs-wavesurfer plugin
import 'videojs-wavesurfer/dist/css/videojs.wavesurfer.css';
import Wavesurfer from 'videojs-wavesurfer/dist/videojs.wavesurfer.js';
*/
// register videojs-record plugin with this import
import "videojs-record/dist/css/videojs.record.css";
import Record from "videojs-record/dist/videojs.record.js";
import "webrtc-adapter";
import RecordCountDown from "./recordCountDown";
// import logo1 from "../../../../public/images/icons/account-das.svg";
import VideoJS from "./simpleVideoJSPlayer";

class VideojsRecordPlayer extends React.Component {
  hideClass = "vjs-hidden-custom";
  state = {
    showCountDown: false, //"d-none",
    hasRecordedVideo: false,
    isOrientationPortrait: window.innerHeight > window.innerWidth,
  };

  constructor(props) {
    super(props);
    this.state = this.state;
    this.updateOrientation = this.updateOrientation.bind(this);
  }

  landscapeWidth = 1280;
  landscapeHeight = 720;
  portraitWidth = 450;
  portraitHeight = 800;

  getIsSafari() {
    const userAgent = navigator.userAgent.toLowerCase();
    return /safari/.test(userAgent) && /iphone|ipod|ipad/.test(userAgent) && !/chrome/.test(userAgent);
  }

  getThumbnailUrl() {
    const isSafari = this.getIsSafari();
    const posterImage = "../images/poster_image.jpg";

    if (isSafari && this.state.hasRecordedVideo) {
      return posterImage;
    }

    return undefined;
  }

  onRecordPress(e) {
    console.log(e);
    e.preventDefault();
    let recorder = this.player.record();
    if (!recorder.isRecording()) {
      this.setState({ showCountDown: true }, () => {
        setTimeout(() => {
          this.setState({ showCountDown: false }, () => {
            if (!recorder.isRecording()) {
              recorder.start();
            }
          });
        }, 3000);
      });
      // this.setState({ showCountDown: true }); //"vid-count-down" });

      // setTimeout(() => {
      //   this.setState({ showCountDown: false }); //"d-none" });
      //   if (!recorder.isRecording()) {
      //     recorder.start();
      //   }
      // }, 3000);
    }
    if (recorder.isRecording()) {
      recorder.stop();
    }
  }

  getOS() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    // Windows
    if (/Win/.test(userAgent)) {
      return "Windows";
    }

    // Android
    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    // macOS
    if (/Macintosh|MacIntel|MacPPC|Mac68K/.test(userAgent)) {
      return "macOS";
    }

    // Linux
    if (/Linux/.test(userAgent)) {
      return "Linux";
    }

    return "other";
  }

  initializePlayer() {
    const isDesktop = this.getOS() === "Windows" || this.getOS() === "Linux" || this.getOS() === "macOS";
    const isPortrait = window.screen.orientation.type.includes("portrait");
    const isLandscape = window.screen.orientation.type.includes("landscape");

    const initVideoPlayer = () => {
      this.player = videojs(this.videoNode, this.options(), () => {
        if (this.getIsSafari()) {
          this.getPoster().addClass(this.hideClass);
        }
        this.getControlBar().addClass(this.hideClass);
        this.player.recordToggle.handleClick = this.onRecordPress.bind(this);
      });

      this.player.on("deviceReady", () => {
        this.getControlBar().removeClass(this.hideClass);
        this.getProgressControl().addClass(this.hideClass);
      });

      this.player.on("startRecord", () => {
        this.setState({ hideProgress: true });

        this.getProgressControl().addClass(this.hideClass);
      });

      this.player.on("finishRecord", async () => {
        this.getProgressControl().removeClass(this.hideClass);
        this.getProgressControl().removeClass("vjs-hidden");
        this.setState({ hideProgress: false });

        if (this.getIsSafari()) {
          this.getPoster().removeClass(this.hideClass);
        }

        if (this.player.recordedData?.type.includes("webm")) {
          getSeekableBlob(this.player.recordedData, async (seekable) => {
            let duration = Math.floor(this.player.record().getDuration());
            if (duration === undefined || duration === null) {
              duration = await getBlobDuration(seekable);
            }
            this.props.onFinish({ file: seekable, duration });
            this.player.recordedData = seekable;
          });
        } else {
          let duration = Math.floor(this.player.record().getDuration());
          if (duration == undefined || duration == null) {
            duration = await getBlobDuration(this.player.recordedData);
          }
          this.props.onFinish({ file: this.player.recordedData, duration });
        }
      });

      this.player.on("error", (element, error) => {
        console.warn(error);
      });

      this.player.on("deviceError", () => {
        message.error("Permission for camera or microphone is not given");
      });
    };

    if (
      (this.props.portrait && isPortrait) ||
      (this.props.portrait && isDesktop && isLandscape) ||
      (!this.props.portrait && isLandscape)
    ) {
      initVideoPlayer();
    }
  }

  reinitializePlayer() {
    this.initializePlayer();
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateOrientation);
    this.updateOrientation();
    // this.state = {
    //   hideProgress: true,
    //   showCountDown: false, //"d-none",
    // // isOrientationPortrait: true,
    //   isOrientationPortrait: window.innerHeight > window.innerWidth
    // };
    this.initializePlayer();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isOrientationPortrait !== this.state.isOrientationPortrait) {
      this.initializePlayer();
    }
  }

  shouldComponentUpdate(nextProps, nextStates) {
    if (
      this.state.isOrientationPortrait !== nextStates.isOrientationPortrait ||
      this.state.showCountDown !== nextStates.showCountDown ||
      this.state.uploadedVideoPreviewSrc !== nextStates.uploadedVideoPreviewSrc ||
      this.state.uploadedVideoPreviewType !== nextStates.uploadedVideoPreviewType ||
      this.state.hideProgress !== nextStates.hideProgress
    ) {
      return true;
    }
    return false;
  }
  // destroy player on unmount
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateOrientation);
    if (this.player) {
      this.player.dispose();
    }
  }
  ResponseForm;

  getProgressControl() {
    return this.getControlBar().getChild("progressControl");
  }
  getControlBar() {
    return this.player.getChild("controlBar");
  }

  getPoster() {
    return this.player.getChild("posterImage");
  }

  getVideoWidth(isPortrait) {
    const isOrientationPotrait = window.screen.orientation.type.includes("portrait");
    const platform = this.getOS();
    if (platform == "Android") {
      if (isPortrait) {
        if (isOrientationPotrait) {
          return this.portraitHeight;
        } else {
          return this.landscapeWidth;
        }
      } else {
        return this.landscapeWidth;
      }
    }
    if (platform == "Linux" || platform == "Windows" || platform == "macOS") {
      if (this.props.portrait) {
        return this.portraitWidth;
      } else {
        return this.landscapeWidth;
      }
    }
  }

  getVideoHeight(isPortrait) {
    const isOrientationPotrait = window.screen.orientation.type.includes("portrait");
    const platform = this.getOS();
    if (platform == "Android") {
      if (isPortrait) {
        if (isOrientationPotrait) {
          return this.portraitWidth;
        } else {
          return this.landscapeHeight;
        }
      } else {
        return this.landscapeHeight;
      }
    }
    if (platform == "Linux" || platform == "Windows" || platform == "macOS") {
      if (this.props.portrait) {
        return this.portraitHeight;
      } else {
        return this.landscapeHeight;
      }
    }
  }

  updateOrientation() {
    this.setState({ isOrientationPortrait: window.innerHeight > window.innerWidth });
  }

  options() {
    const isSafari = this.getIsSafari();
    const videoJsOptions = {
      controls: true,
      poster: isSafari ? "../images/poster_image.jpg" : undefined,
      bigPlayButton: true,
      fluid: false,
      responsive: true,
      width: 640,
      height: 480,
      aspectRatio: this.props.portrait ? "9:16" : "16:9",
      controlBar: {
        volumePanel: {
          inline: false,
          volumeControl: {
            vertical: true,
          },
        },
        progressControl: true,
        fullscreenToggle: false, // Remove fullscreenToggle option because it was bugging
      },
      plugins: {
        record: {
          // videoMimeType: isSafari ? "video/mp4" : "video/webm",
          audio: true,
          video: {
            width: {
              ideal: this.getVideoWidth(this.state.isOrientationPortrait),
            },
            height: {
              ideal: this.getVideoHeight(this.state.isOrientationPortrait),
            },
          },
          maxLength: this.props.recordingDuration / 1000 - 1,
          displayMilliseconds: false,
          debug: false,
          frameWidth: 1000,
          frameHeight: 200,
        },
      },
    };

    // use correct video mimetype for opera
    if (!!window.opera || navigator.userAgent.indexOf("OPR/") !== -1) {
      videoJsOptions.plugins.record.videoMimeType = "video/webm;codecs=vp8"; // or vp9
    }
    return videoJsOptions;
  }

  handleBodyOverflow(showMessage) {
    if (showMessage) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }
  getIsSafari() {
    const userAgent = navigator.userAgent.toLowerCase();
    return /safari/.test(userAgent) && /iphone|ipod|ipad/.test(userAgent) && !/chrome/.test(userAgent);
  }

  render() {
    let params = new URLSearchParams(document.location.search);
    let shouldLoop = params.get("loop") || false;
    const OS = this.getOS();
    const isSafari = this.getIsSafari();
    const posterImage = "../images/poster_image.jpg";
    const { hasRecordedVideo } = this.state;

    const isDesktop = OS === "Windows" || OS === "Linux" || OS === "macOS";
    const isLandscape = window.screen.orientation.type.includes("landscape");
    const isPortrait = window.screen.orientation.type.includes("portrait");

    if (!isDesktop && ((this.props.portrait && isLandscape) || (!this.props.portrait && isPortrait))) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return (
      <>
        {this.state.showCountDown ? <img src="../images/startcountdown.gif" className="vid-count-down" alt="" /> : null}
        {this.state.uploadedVideoPreviewSrc ? (
          <VideoJS
            options={{
              bigPlayButton: false,
              autoplay: true,
              controls: true,
              controlBar: {
                remainingTimeDisplay: false,
                currentTimeDisplay: true,
                timeDivider: true,
                durationDisplay: true,
                volumePanel: {
                  inline: false,
                  volumeControl: {
                    vertical: true,
                  },
                },
                progressControl: true,
                pictureInPictureToggle: false,
                fullscreenToggle: true,
              },
              responsive: true,
              fluid: true,
              sources: [
                {
                  src: this.state.uploadedVideoPreviewSrc,
                  type: this.state.uploadedVideoPreviewType,
                },
              ],
            }}
          />
        ) : (
          <>
            {this.state.timer ? (
              <RecordCountDown />
            ) : (
              <>
                <div data-vjs-player className="w-100">
                  <div className="w-100 mx-auto" style={this.props.portrait ? { maxWidth: "500px" } : {}}>
                    {isDesktop && isLandscape ? (
                      <video
                        id="myVideo"
                        ref={(node) => (this.videoNode = node)}
                        className="video-js rotate-video"
                        playsInline
                        controls
                        preload="true"
                      ></video>
                    ) : this.props.portrait ? (
                      isPortrait ? (
                        <div>
                          <div style={{ display: "block" }}>
                            <video
                              id="myVideo"
                              ref={(node) => (this.videoNode = node)}
                              className="video-js rotate-video"
                              playsInline
                              controls
                              preload="true"
                            ></video>
                          </div>
                          <p className="prompt-msg" style={{ display: "none" }}>
                            Please turn your phone into portrait to start recording
                          </p>
                        </div>
                      ) : (
                        <p className="prompt-msg">Please turn your phone into portrait to start recording</p>
                      )
                    ) : isLandscape ? (
                      <div>
                        <div style={{ display: "block" }}>
                          <video
                            id="myVideo"
                            ref={(node) => (this.videoNode = node)}
                            className="video-js rotate-video"
                            playsInline
                            controls
                            preload="true"
                          ></video>
                        </div>
                        <p className="prompt-msg" style={{ display: "none" }}>
                          Please turn your phone into landscape to start recording
                        </p>
                      </div>
                    ) : (
                      <p className="prompt-msg">Please turn your phone into landscape to start recording</p>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    bottom: 0,
                    left: "50%",
                    transform: "translateX(-50%)",
                    minWidth: "220px",
                  }}
                >
                  <Upload
                    accept="video/mp4,video/webm"
                    showUploadList={false}
                    multiple={false}
                    beforeUpload={() => false}
                    onChange={async ({ file }) => {
                      if (!file.type.includes("video")) {
                        notification.error({
                          message: "Invalid file type.",
                          description: "Only video files are allowed.",
                        });
                        return;
                      }
                      let duration = await getBlobDuration(file);
                      let uploadedVideoDuration = duration * 1000;
                      let recordingDurationInSeconds = +this.props.recordingDuration / 1000 - 1;
                      let durationErrorText = `${recordingDurationInSeconds} seconds`;
                      if (recordingDurationInSeconds >= 60) {
                        let minutes = Math.floor(recordingDurationInSeconds / 60);
                        let seconds = Math.ceil(recordingDurationInSeconds - minutes * 60);
                        durationErrorText = `${minutes} minute(s) ${seconds !== 0 ? `and ${seconds} seconds` : ""}`;
                      }
                      if (uploadedVideoDuration > +this.props.recordingDuration) {
                        message.error(`Video duration should not be greater than ${durationErrorText}`);
                      } else {
                        const allowedExtensions = ["video/mp4", "video/webm"];
                        // if (!allowedExtensions.includes(file.type)) {
                        //   notification.error({
                        //     message: "Invalid file type.",
                        //     description: "Only webm or mp4 files are allowed.",
                        //   });
                        //   return;
                        // }
                        URL.createObjectURL(file);
                        this.setState({
                          uploadedVideoPreviewSrc: URL.createObjectURL(file),
                          uploadedVideoPreviewType: file.type,
                        });
                        this.props.onFinish({
                          file: file,
                          duration: duration ? Math.ceil(duration) : 0,
                        });
                      }
                    }}
                  >
                    {!shouldLoop && (
                      <>
                        or
                        <Button
                          className="upload-btn-link"
                          type="link"
                          style={{
                            padding: 0,
                          }}
                        >
                          UPLOAD A VIDEO{" "}
                          <Typography.Text className="ml-1" type="secondary">
                            {" "}
                            (mp4/webm only)
                          </Typography.Text>
                        </Button>
                      </>
                    )}
                  </Upload>
                </div>
              </>
            )}
          </>
        )}
      </>
    );
  }
}

export default VideojsRecordPlayer;
